@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=auto')

$dark: #111A29
$grey: #9e9e9e
$lgrey: scale-color($grey, $lightness: -25%)
$llgrey: scale-color($grey, $lightness: -20%)
$light: #F7F7F8
$pink: #FF2F93
$blue: #277CB9
$yellow: #F1BD00
$violet: #5F6095
$orange: #EB861C
$red: rgba(247,25,25,0.75)
$white: #fff
$logo: rgba(120, 141, 151, 0.75)
$start: #C5D290

$body: $white
$button: $pink
$theme: $blue
$footer: $light

$basic: 'Poppins', sans-serif
$intro: 'Poppins', sans-serif

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
