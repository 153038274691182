#hero
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  background: #000813 url('../../assets/hero.webp') center center no-repeat
  background-size: cover
  +v-xs
    height: auto
    padding: 40px 0 0
#cta
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  background: $dark url('../../assets/legal.webp') center center no-repeat
  background-blend-mode: overlay
  background-size: cover
  +v-xs
    height: 80vh
    padding: 20px 0 100px !important
  .w-50
    +v-xs
      width: 90% !important
      min-width: 90% !important
      max-width: 90% !important
