html
  font-family: $basic
  font-weight: normal
  font-size: 1rem
  font-display: auto

body
  width: 100%
  max-width: 100% !important
  color: $dark
  font-size: 95%
  font-family: $basic
  font-weight: 400
  font-smooth: always
  line-height: 1.6
  -webkit-font-smoothing: antialised
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden
  background-color: $body

.card
  background-color: transparent
  border: 1px solid $blue
  *
    color: $white !important

#pricing
  .list-unstyled
    height: 280px
    +v-sm
      height: auto
      margin-left: 19.5%
    +v-xs
      margin-left: auto
    li
      display: inline-flex
      padding: 4px 0
      svg
        transform: translateY(4px)

.cta-form
  color: $white
  img
    width: 160px
    margin-left: 16px
  section
    display: inline-flex
    align-items: center
    transform: translate(10px,-4px)
    .paypal-img
      width: auto
      zoom: 1.3
      filter: invert(1)
      transform: translate(-8px,1px)

#requirements
  .h-300
    +v-xs
      min-height: 420px !important

.c-list
  list-style: none
  margin: 0
  padding: 8px 0 0 8px
  line-height: 1.75
  strong
    padding-left: 4px
    font-weight: 500 !important

.l-8
  padding: 8px 0 0 8px

button
  outline: none !important
  border: none !important
  box-shadow: none !important
  &.navbar-toggler
    padding: 0 !important

#conditions
  *
    color: $white !important

.logo
  transform: translateY(3px)

.bg-dark
  background-color: $dark

footer
  *
    font-weight: 300
  .flex
    +v-xs
      display: block !important
      *
        text-align: center !important
      .menu-footer
        margin-top: 16px

.page
  *
    color: #444

a, * a
    color: inherit
    text-decoration: none
