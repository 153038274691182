.title
  margin: 0
  padding: 0
  +v-xs
    font-size: 1.2em
  &-cta
    font-size: 4em
    font-weight: 200
    +v-xs
      font-size: 2.5em
    &-mini
      font-size: 2em
      font-weight: 200 !important
  &-intro
    font-size: 4.75em
    font-weight: 500
    +v-sm
      font-size: 3.5em
    +v-xs
      font-size: 2em
      &.w-70
        width: 90% !important
        min-width: 90% !important
        max-width: 90% !important
    &-sub
      font-size: 1.75em
      font-weight: 200
  &-section
    font-size: 3em
    font-weight: 500
    line-height: 1.25
    letter-spacing: 3%
    +v-xs
      font-size: 1.5em
    &-base
      font-size: 1em
      font-weight: 500
    &-mini
      text-transform: uppercase
    &-sub
      font-size: 1.35em
      font-weight: 300
      line-height: 1.4
      text-transform: none
      +v-xs
        font-size: 1.05em
        &.w-64
          width: 80% !important
          min-width: 80% !important
          max-width: 80% !important
  &-mini
    padding: 0 6px
    font-size: .85em
    font-weight: 500
    line-height: 1

.lead
  +v-xs
    font-size: 1.25em
    line-height: 1.25
    &.w-60
      width: 90% !important
      min-width: 90% !important
      max-width: 90% !important
