header
  height: 80px
  position: relative
  top: 0
  height: 80px
  margin-bottom: -80px
  background: transparent

.site
  &-title
    span
      font-size: .65em

.navbar-toggler
  position: relative
  top: 2px
  right: 8px
  width: 24px
  height: 22px
  span
    width: 24px
    height: 2px
    display: block
    background-color: $grey
    transition: all ease-out .3s
    transform: rotate(0)
    &.one
      position: absolute
      top: 0
      left: 0
    &.two
      position: absolute
      top: 8px
      left: 0
    &.three
      position: absolute
      top: 16px
      left: 0
  &.opened
    span
      &.one
        transform: rotate(45deg) translate(3px,8px)
      &.two
        left: 12px
        width: 0
        height: 0
        visibility: hidden
      &.three
        transform: rotate(-45deg) translate(3px,-8px)
.navbar-nav
  +v-sm
    padding-top: 24px
    min-height: 100vh
  li
    +v-sm
      padding: 16px 4px
      border-top: 1px solid #dedede
    &.last
      padding-right: 16px
      +v-sm
        padding-bottom: 0
    &.has-btn
      +v-sm
        padding: 0 4px 8px !important
        border-top: none !important
        transform: translateY(-4px)
  .button
    +v-sm
      padding: 24px 0
      min-width: 100% !important
    +v-xs
      padding: 24px 0 !important
      min-width: 100% !important
